import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from 'react-bootstrap'

import PocilySlider from '../components/index/policySlider'
import EcoChangePR from '../components/index/ecoChangePR'
import McmPR from '../components/index/mcmPR'
import ContentsSlider from '../components/index/contentsSlider'

const IndexPage = ({location}) => {

  const data = useStaticQuery(graphql`
    query {
      bugyo: file(relativePath: { eq: "bugyoLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      w2ms: file(relativePath: { eq: "sub_product_w2ms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      gdata: file(relativePath: { eq: "sub_product_gdata_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      oken: file(relativePath: { eq: "oken.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      pca: file(relativePath: { eq: "pca.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      RakurakuSeisan: file(relativePath: { eq: "sub_product_RakurakuSeisan.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      productsIcon: file(relativePath: { eq: "icons/products.svg" }) {
        publicURL
      },
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  `)

  const NewTopics = () => {
    const posts = data.allMarkdownRemark.nodes
    if ( posts.length > 0) {
      return (
        <div id="new-topics">
          <Container lg="auto">
            <p>
            { posts.map( (post, index) => {
              const date = post.frontmatter.date
              const title = post.frontmatter.title || post.fields.slug
              return ( 
                <Link to="/topics/" key={index}>
                  <strong>{date}</strong>{`　`}{title}<br/>
                </Link>
              )
            })}
            </p>
          </Container>
        </div>
      )
    }
  }

  const PartnerProducts = () => (
    <Container xs='auto' style={{ maxWidth: '850px' }}>
      <Row className="align-items-center text-center justify-content-center shadow-box" style={{ padding: '8rem 4rem' }} >
        <Col lg={2} className="p-0">
          <Img 
            fluid={data.w2ms.childImageSharp.fluid} 
            alt="W2MS" 
            style={{ height: '80px', margin: 'auto' }} 
            imgStyle={{ objectFit: 'contain' }}
          />
        </Col>
        <Col lg={2} className="p-0">
          <Img 
            fluid={data.bugyo.childImageSharp.fluid} 
            alt="奉⾏製品"
            style={{ height: '80px', margin: 'auto' }} 
            imgStyle={{ objectFit: 'contain' }}
          />
        </Col>
        <Col lg={2} className="p-0">
          <Img 
            fluid={data.oken.childImageSharp.fluid} 
            alt="大臣エンタープライズ"
            style={{ height: '80px', margin: 'auto' }}  
            imgStyle={{ objectFit: 'contain' }}
          />
        </Col>
        <Col lg={2} className="p-0">
          <Img 
            fluid={data.pca.childImageSharp.fluid} 
            alt="大臣エンタープライズ"
            style={{ height: '80px', margin: 'auto' }}  
            imgStyle={{ objectFit: 'contain' }}
          />
        </Col>
        <Col lg={2} className="p-0">
          <Img 
            fluid={data.RakurakuSeisan.childImageSharp.fluid} 
            alt="楽楽清算"
            style={{ height: '80px', margin: 'auto' }} 
            imgStyle={{ objectFit: 'contain' }}
          />
        </Col>
      </Row>
    </Container>
  )
  
  return (
    <Layout location={location} title="TOP" >
      <SEO title="TOP" />
      <PocilySlider />
      <NewTopics />
      <EcoChangePR />
      <McmPR />   
      <ContentsSlider />
      <PartnerProducts />
    </Layout>
  )
}

export default IndexPage
