import React, { forwardRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Row, Col, Button } from 'react-bootstrap'
import { Timeline, Tween, Reveal } from 'react-gsap'

const EcoChangePR = () =>  {

  const data = useStaticQuery(graphql`
    query {
      ecoChangeLogo: file(relativePath: { eq: "ecochange_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      EDIAuthLogo: file(relativePath: { eq: "EDI-Authlogo_P.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      ecoChangeBack: file(relativePath: { eq: "illust/ecochange-back.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      internetIcon: file(relativePath: { eq: "icons/internet.svg" }) {
        publicURL
      },
      startIcon: file(relativePath: { eq: "icons/start.svg" }) {
        publicURL
      },
      cloudUploadIcon: file(relativePath: { eq: "icons/cloud-upload.svg" }) {
        publicURL
      },
      earthIcon: file(relativePath: { eq: "icons/earth.svg" }) {
        publicURL
      }
    }
  `)

  const Contents = forwardRef((props, ref) => (
    <div className="pb-5">
      <div ref={div => ref.set('div1', div)} >
        <h1>
          <span className="p-blue">企業規模、業種</span>を問わず、<br className="d-none d-lg-block" />
          ⾒積から受発注、請求、⽀払まで<br className="d-none d-lg-block" />
          企業間取引の<span className="p-blue">デジタル化</span>を実現。
        </h1>
      </div>
      <div ref={div => ref.set('div2', div)}>
        <p>
          「EcoChange」とは、簡単・便利・低コストを⽬指し<br className="d-none d-lg-block" />
          ⼤⼿・中堅から中⼩企業までの受発注業務に最適化、標準化したＥＤＩです。<br className="d-none d-lg-block" />
          インターネット回線を利⽤しているので、2024年の通信回線のIP網移⾏にも対応しています。<br className="d-none d-lg-block" />
          クラウド・オンプレ問わず、APIで貴社基幹システムとシームレスに連携。<br className="d-none d-lg-block" />
          スマートフォン・タブレット対応なので、PCがなくてもいつでもどこでも受発注業務ができます。<br className="d-none d-lg-block" />
        </p>
      </div>
      <div ref={div => ref.set('div3', div)} className="text-center text-lg-left mt-5 mb-5" >
        <Button
          variant="primary" 
          href="https://www.ecochange.jp/" 
          target="_blank" rel="noopener noreferrer"
        >
          EcoChange 公式サイトはこちら
        </Button>
      </div>
      <div ref={div => ref.set('div4', div)} className="text-center text-lg-left">
        <div className="shadow-box">
          <Row>
            <Col lg="auto" className="m-0 p-0">
              <div className="ecochnage-icons">
                <img src={data.internetIcon.publicURL} alt="通信⽅式の変更をスムーズに"/>
                <p>通信⽅式の変更を<br/>スムーズに</p>
              </div>
              <div className="ecochnage-icons">
                <img src={data.startIcon.publicURL} alt="すぐに始められる"/>
                <p>すぐに始められる<br/>{`　`}</p>
              </div>
            </Col>
            <Col lg="auto" className="m-0 p-0">
              <div className="ecochnage-icons">
                <img src={data.cloudUploadIcon.publicURL} alt="取引先を⼀元管理"/>
                <p>取引先を⼀元管理<br/>{`　`}</p>
              </div>
              <div className="ecochnage-icons">
                <img src={data.earthIcon.publicURL} alt="国連CEFACTに準拠"/>
                <p>国連CEFACTに準拠<br/>{`　`}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  ))

  const Wrapper = forwardRef((props, ref)=> (
    <div className="product-PR">
      <Row className="mb-4 align-items-center">
        <Col lg="3">
          <Img fluid={data.ecoChangeLogo.childImageSharp.fluid} alt="EcoChange" className="m-auto ml-lg-0"/>
        </Col>
        <Col className="text-right">
          <Img fluid={data.EDIAuthLogo.childImageSharp.fluid} alt="ITCA 中小企業共通EDI準拠 共通EDIプロバイダ認証" className="mx-auto ml-lg-0"/>
        </Col>
      </Row>
      <div className="bg-img">
        <Img fluid={data.ecoChangeBack.childImageSharp.fluid} alt="EcoChange"/>
      </div>
      {props.children}
    </div>
  ))

  return (
    <Container lg="auto">
      <Reveal trigger={<Wrapper />}>
        <Timeline target={<Contents />}>
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} target="div1"/>
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} target="div2"/>
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} target="div3"/>
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} target="div4"/>
        </Timeline>
      </Reveal>
    </Container>
  )
}

export default EcoChangePR